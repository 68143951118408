.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Additional CSS for the app */

#pre-instance-message-container > ul {
  list-style: disc;
}

#pre-instance-message-container > ul > li > ul {
  list-style: disc;
}

.sui-layout-header {
  padding: 24px 24px !important;
}

.border-shine {
  position: relative;
  border-top: 2px solid rgb(41 181 232);
}

.border-shine::before {
  content: "";
  position: absolute;
  top: -2px; /* Same as border-top width */
  left: 0;
  height: 2px; /* Same as border-top width */
  width: 200%; 
  background: linear-gradient(110deg, rgb(41 181 232) 8%, rgb(196, 237, 252) 18%, rgb(41 181 232) 33%);
  background-size: 200% 100%;
  animation: 1.2s shine linear infinite;
}

.bg-shine {
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.2s shine linear infinite;
}

.bg-in-progress {
  background: linear-gradient(110deg, rgb(41 181 232) 8%, #f5f5f5 18%, rgb(41 181 232) 33%);
  background-size: 200% 100%;
  animation: 1.2s shine linear infinite;
}

.bg-pending {
  background: linear-gradient(110deg, rgb(185, 185, 185) 8%, #f5f5f5 18%, rgb(185, 185, 185) 33%);
  background-size: 200% 100%;
  animation: 1.2s shine linear infinite reverse;
}

.bg-failed {
  background: rgb(139 16 63);
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

/* Footer */